body {
  margin-left: 20px;
  margin-right: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Main {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.skill-bord {
  display: inline-block;
  text-align: center;
}

h2 {
  font-size: 35px;
  font-family: Garamond;
  text-align: center;
  margin-bottom: 40px;
}

h5 {
  font-family: Garamond;
  text-align: center;
}

p {
  font-family: Garamond;
  font-size: 18px;
}

.like-raw {
  text-align: center;
}

.skill-h2{
  margin-top: 50px;
  margin-bottom: 20px;
}
.skill-ptag{
  font-family: Garamond;
  font-size: 18px;
  margin-left: 20px
}

.like-h2tag{
  margin-top: 30px;
  margin-bottom: 50px;
}

.like-ptag {
  margin-bottom: 50px;
}

.About {
  margin-top: 50px;
  margin-right: 30px;
}

.mr-auto {
  justify-content: right;
}

.bio {
  margin-top: 50px;
  margin-bottom: 30px;
}

.headshot {
  text-align: center;
}

.about-hr {
  size: 10px;
}

.skill-card {
  display: inline-block;
  width: 150px;
  height: 200px;
  margin: 30px;
  margin-bottom: 0;
    
}
.like-top {
  display: inline-block;
  margin: 30px;
  cursor: pointer;
}

.like-modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
}

.like-modal-box {
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
  width: 640px;
  max-width: 100%;
  height: 220px;
  max-height: 100%;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  margin: auto;
  background-color: aliceblue;
  border-radius: 12px;
  overflow: auto;
}

.like-modal-box p{
  margin-bottom: 5px;
}

.like-close-button {
  color: skyblue;
  border-style: none;
  background-color: aliceblue;
  margin-bottom: 10px;
}


.links {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    float: left;
    display: inline;
}

li a{
    display: block;
}

.github {
    margin-right: 60px;
}
.contact-form {
    height: 500px;
}
.contact-submit-message {
    color: green;
}

.email-message-error {
    color: red;
}

.message-message-error {
    color: red;
}

.message-send-button {
    align-items: center;
    text-align: center;
    justify-content: center;

}

.name-input {
    width: 500px;
}

.email-input{
    width: 500px;
}

.social-icon {
    display: inline-block;
}

textarea {
    width: 500px;
    height: 200px;
}

.contact-h2tag {
    margin-top: 20px;
    margin-bottom: 40px;
}
.footer h6 {
  font-family: Garamond;
  text-align: center;
  color: #787878;
}
