.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Main {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.skill-bord {
  display: inline-block;
  text-align: center;
}

h2 {
  font-size: 35px;
  font-family: Garamond;
  text-align: center;
  margin-bottom: 40px;
}

h5 {
  font-family: Garamond;
  text-align: center;
}

p {
  font-family: Garamond;
  font-size: 18px;
}

.like-raw {
  text-align: center;
}

.skill-h2{
  margin-top: 50px;
  margin-bottom: 20px;
}
.skill-ptag{
  font-family: Garamond;
  font-size: 18px;
  margin-left: 20px
}

.like-h2tag{
  margin-top: 30px;
  margin-bottom: 50px;
}

.like-ptag {
  margin-bottom: 50px;
}