
.links {
    display: -webkit-flex;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    float: left;
    display: inline;
}

li a{
    display: block;
}

.github {
    margin-right: 60px;
}